#home-page {
  margin-top: 1rem;
  margin-bottom: 80px;
  * {
    margin: 0.2rem 0 0.2rem 0;
  }

  .about-me {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    * {
      width: 50%;
    }

    &-text > * {
      width: 100%;
      padding-top: 0.2rem;
      padding-right: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

.home-row {
  & > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.link-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.3);
  margin: 1rem 1rem 1rem 1rem !important;
  padding: 2rem 2rem !important;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
  }
}
